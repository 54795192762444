import React from "react";
import "./footer.css";
import logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="genz__footer">
      <div className="genz__footer-links-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="genz__footer-links">
        <div className="genz__footer-links-container">
          <p>
            <a>Contact</a>
          </p>
          <p>
            <a>Privacy & Cookie Policy</a>
          </p>
          <p>
            <a>Terms & Conditions</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
