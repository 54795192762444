import { useState } from "react";
import React from "react";

import "./contacts.css";

const Contacts = () => {
  const formInitialDetails = {
    name: "",
    phone: "",
    email: "",
    company: "",
    message: "",
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("send");
  const [status, setStatus] = useState({});
  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    let response = await fetch("http://localhost:3000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Send");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: "Message sent successfully" });
    } else {
      setStatus({
        succes: false,
        message: "Something went wrong, please try again later.",
      });
    }
  };

  return (
    <div className="genz__contacts section__padding" id="contacts">
      <div className="genz__contacts-intro">
        <p>WE WOULD LOVE TO HEAR FROM YOU</p>
        <h2>say hello!</h2>
      </div>
      <div className="genz__contacts-form">
        <p>Have a question? or just say hello!</p>
        <form onSubmit={handleSubmit}>
          <div className="genz__contacts-form_col">
            <div className="genz__contacts-form_div">
              <div className="single_form">
                <label>Name:</label>
                <input
                  type="text"
                  value={formDetails.name}
                  autoComplete="off"
                  // placeholder="Name"
                  onChange={(e) => onFormUpdate("name", e.target.value)}
                ></input>
              </div>
            </div>
            <div className="genz__contacts-form_div">
              <div className="single_form">
                <label>phone:</label>
                <input
                  type="tel"
                  value={formDetails.phone}
                  // placeholder="Phone"
                  onChange={(e) => onFormUpdate("phone", e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div className="genz__contacts-form_col">
            <div className="genz__contacts-form_div">
              <div className="single_form">
                <label>Email:</label>
                <input
                  type="email"
                  value={formDetails.email}
                  // placeholder="Email"
                  onChange={(e) => onFormUpdate("email", e.target.value)}
                ></input>
              </div>
            </div>
            <div className="genz__contacts-form_div">
              <div className="single_form">
                <label>Company:</label>
                <input
                  type="text"
                  value={formDetails.company}
                  // placeholder="Company"
                  onChange={(e) => onFormUpdate("company", e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div className="genz__contacts-form_div">
            <div className="single_form">
              <label>Message:</label>
              <textarea
                rows="6"
                value={formDetails.message}
                // placeholder="Message"
                onChange={(e) => onFormUpdate("message", e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="genz__contacts-form_button"><button type="submit">
            <span>{buttonText}</span>
          </button></div>
          
          {status.message && (
            <p className={status.success === false ? "danger" : "success"}>
              {status.message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Contacts;
