import React, { useState, useEffect } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../../assets/logo.png";
import "./navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className={scrolled ? "scrolled" : ""}>
      <div className="genz__navbar">
        <div className="genz__navbar-links-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="genz__navbar-links">
          <div className="genz__navbar-links-container">
            <p>
              <a href="#home">Home</a>
            </p>
            <p>
              <a href="#about">About Us</a>
            </p>
            <p>
              <a href="#service">Services</a>
            </p>
            <p>
              <a href="#work">Work</a>
            </p>
          </div>
        </div>
        <div className="genz__navbar-sign">
          <button
            type="button"
            onClick={() => (window.location.href = "#contacts")}
          >
            Contact Us
          </button>
        </div>

        <div className="genz__navbar-menu">
          {toggleMenu ? (
            <RiCloseLine
              color="#fff"
              size={50}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenu3Line
              color="#fff"
              size={50}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <div className="genz__navbar-menu_container scale-up-center">
              <div className="genz__navbar-menu_container-links">
                <p>
                  <a href="#home">Home</a>
                </p>
                <p>
                  <a href="#about">About Us</a>
                </p>
                <p>
                  <a href="#service">Services</a>
                </p>
                <p>
                  <a href="#work">Work</a>
                </p>
                <p>
                  <a href="#contact">Contact US</a>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
