import React from "react";
import "./client.css";

import { c1, c2, c3, c4, c5 } from "./imports";

const Client = () => {
  return (
    <div className="genz__client section__padding" id="client">
      <p>Our Client Community</p>
      <div className="genz__client-image">
        <div>
          <img src={c1} alt="client 1" />
        </div>
        <div>
          <img src={c2} alt="client 2" />
        </div>
        <div>
          <img src={c3} alt="client 3" />
        </div>
        <div>
          <img src={c4} alt="client 4" />
        </div>
        <div>
          <img src={c5} alt="client 5" />
        </div>
      </div>
    </div>
  );
};

export default Client;
