import React from "react";
import "./header.css";
import l1 from "../../assets/l1.jpg";
import l2 from "../../assets/l2.jpg";
import l3 from "../../assets/l3.jpg";
import l4 from "../../assets/l4.jpg";
import l5 from "../../assets/l5.jpg";

const Header = () => {
  return (
    <div className="genz__header section__padding" id="home">
      <div className="genz__header-content">
        <div className="genz__header-content_image">
          <img src={l1} alt="a1" />
        </div>
        <div className="genz__header-content_center">
          <h1 className="gradient__text">
            Elevating Your Brand Through Innovation
          </h1>
          <p>
            Welcome to Gen-Z Innovates, the epicenter of digital innovation for
            the creative minds of this generation. Unleash your brand's
            potential with our diverse range of services. Explore digital
            excellence, redefine your story.
          </p>
          <div className="genz__header-content__input">
            <input type="email" placeholder="name@email.com"></input>
            <button type="button">Get notified</button>
          </div>
        </div>
        <div className="genz__header-content_image">
          <img src={l5} alt="a5" />
        </div>
      </div>
      <div className="genz__header-images">
        <div className="genz__header-image1">
          <img src={l2} alt="a2" />
        </div>
        <div className="genz__header-image2">
          <img src={l3} alt="a3" />
        </div>
        <div className="genz__header-image3">
          <img src={l4} alt="a4" />
        </div>
      </div>
    </div>
  );
};

export default Header;
