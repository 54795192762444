import React from "react";
import "./cta.css";
import c1 from "../../assets/c1.jpg";
import { CgAirplane } from "react-icons/cg";

const CTA = () => {
  return (
    <div className="genz__cta section__padding" id="cta">
      <div className="genz__cta-content">
        <div className="genz__cta-content_text">
          <p>
            <CgAirplane />
            Customer story
          </p>
          <h2>
            “Our growth no longer necessitates the recruitment and education of
            additional design professionals”
          </h2>
        </div>
        <div className="genz__cta-content_image">
          <img src={c1} alt="a1" />
        </div>
      </div>
    </div>
  );
};

export default CTA;
