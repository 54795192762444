import React from "react";

import { Header, Footer, Service, Work, CTA, Contacts } from "./containers";
import { Navbar, Client, About } from "./components";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <div className="primary_bg">
        <Navbar />
        <Header />
      </div>
      <Client />
      <About />
      <Service />
      <Work />
      <CTA />
      <Contacts />
      <Footer />
    </div>
  );
};

export default App;
