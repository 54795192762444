import React from "react";
import "./work.css";
import { CgAirplane } from "react-icons/cg";

const Work = () => {
  return (
    <div className="genz__work section__margin" id="work">
      <div className="genz__service-topic">
        <p>
          <CgAirplane />
          How it works
        </p>
        <h2 className=" gradient__text1">
          Top–notch designs, <br /> We create masterpieces.
        </h2>
      </div>
      <div className="genz__service-containers">
        {/* dm */}
        <div className="genz__work-containers1_content">
          <div className="genz__work-containers1_content__image">
            <CgAirplane color="#ddff00" size={50} />
          </div>
          <div className="genz__work-containers_content__text">
            <h3>Tell us your vision</h3>
            <p>
              Choose a plan and share your project details with us: we’re here
              to listen.
            </p>
          </div>
        </div>
        {/* pr */}
        <div className="genz__work-containers1_content">
          <div className="genz__work-containers1_content__image">
            <CgAirplane color="#ddff00" size={50} />
          </div>
          <div className="genz__work-containers_content__text">
            <h3>Public Relations</h3>
            <p>Foster positive brand image and navigate the media landscape.</p>
          </div>
        </div>
        {/* WD */}
        <div className="genz__work-containers1_content">
          <div className="genz__work-containers1_content__image">
            <CgAirplane color="#ddff00" size={50} />
          </div>
          <div className="genz__work-containers_content__text">
            <h3>Web Development </h3>
            <p>
              Crafting innovative websites tailored to enhance user experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
