import React from "react";
import "./about.css";
import { CgAirplane } from "react-icons/cg";

const About = () => {
  return (
    <div className="genz__about section__padding" id="about">
      <p>
        <CgAirplane /> INTRODUCING BOLT
      </p>
      <h2>
        <spam>A creative studio that is </spam>
        Specialized in Brand Strategy and Digital Creation.
        <spam> Your brand, Our innovation touch.</spam>
      </h2>
    </div>
  );
};

export default About;
