import React from "react";
import "./service.css";
import { CgAirplane } from "react-icons/cg";
import { s1, s2, s3, s4, s5 } from "./index.js";

const Service = () => {
  return (
    <div className="genz__service section__margin" id="service">
      <div className="genz__service-topic">
        <p>
          <CgAirplane />
          What you’ll get
        </p>
        <h2 className=" gradient__text1">Strategy . Brand . Design</h2>
      </div>

      <div className="genz__service-containers">
        {/* dm */}
        <div className="genz__service-containers1_content">
          <div className="genz__service-containers1_content__image">
            <img src={s1} alt="digital marketing" />
          </div>
          <div className="genz__service-containers_content__text">
            <h3>Digital Marketing</h3>
            <p>Boost brand reach, engage audiences and drive growth.</p>
          </div>
        </div>
        {/* pr */}
        <div className="genz__service-containers1_content">
          <div className="genz__service-containers1_content__image">
            <img src={s2} alt="Public relation" />
          </div>
          <div className="genz__service-containers_content__text">
            <h3>Public Relations</h3>
            <p>Foster positive brand image and navigate the media landscape.</p>
          </div>
        </div>
        {/* WD */}
        <div className="genz__service-containers1_content">
          <div className="genz__service-containers1_content__image">
            <img src={s3} alt="Web Development" />
          </div>
          <div className="genz__service-containers_content__text">
            <h3>Web Development </h3>
            <p>
              Crafting innovative websites tailored to enhance user experience.
            </p>
          </div>
        </div>
      </div>
      <div className="genz__service-containers">
        {/* ui */}
        <div className="genz__service-containers2_content">
          <div className="genz__service-containers_content__text">
            <h3>UI/UX</h3>
            <p>User experience for impactful digital interactions.</p>
          </div>
          <div className="genz__service-containers2_content__image">
            <img src={s4} alt="Ui/Ux" />
          </div>
        </div>
        {/* brand */}
        <div className="genz__service-containers2_content">
          <div className="genz__service-containers_content__text">
            <h3>Branding</h3>
            <p>Powering brands with strategic identity & design</p>
          </div>
          <div className="genz__service-containers2_content__image">
            <img src={s5} alt="Branding" />
          </div>
        </div>
      </div>
      <div className="genz__service-container">
        <div className="genz__service-container__text">
          <p>
            <CgAirplane color="#ddff00" /> SEO
          </p>
        </div>
        <div className="genz__service-container__text">
          <p>
            <CgAirplane color="#ddff00" /> Corporate Reputation
          </p>
        </div>
        <div className="genz__service-container__text">
          <p>
            <CgAirplane color="#ddff00" /> Mobile Apps
          </p>
        </div>
        <div className="genz__service-container__text">
          <p>
            <CgAirplane color="#ddff00" /> Social Media Marketing
          </p>
        </div>
      </div>
      <div className="genz__service-container">
        <div className="genz__service-container__text">
          <p>
            <CgAirplane color="#ddff00" /> Rollover hours
          </p>
        </div>
        <div className="genz__service-container__text">
          <p>
            <CgAirplane color="#ddff00" /> Premium designers
          </p>
        </div>
        <div className="genz__service-container__text">
          <p>
            <CgAirplane color="#ddff00" /> Multilingual support
          </p>
        </div>
      </div>
    </div>
  );
};

export default Service;
